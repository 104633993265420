<template>
  <div>
    <HeaderDefault :blok="headerDefaultSettings?.content" />
    <slot />
    <FooterDefault :blok="footerDefaultSettings?.content" />
  </div>
</template>

<script setup>
const { setFullSlug, defaultHeaderPath, defaultFooterPath } = useDefaultSettingsPath()
const { defaultSettings } = await useStoryDefaultSettings()

setFullSlug(null)

const { value: headerDefaultSettings } = await defaultSettings(defaultHeaderPath.value)
const { value: footerDefaultSettings } = await defaultSettings(defaultFooterPath.value)
</script>
